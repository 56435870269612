import React from "react"
import BlockContent from "@sanity/block-content-to-react"
const BlockArray = props => {
	const BlockRenderer = props => {
		const style = props.node.style || "normal"

		if (/^h\d/.test(style)) {
			const level = style.replace(/[^\d]/g, "")
			return <h2 className={`my-heading level-${level}`}>{props.children}</h2>
		}

		if (/^\d/.test(style)) {
			const level = style.replace(/[^\d]/g, "")
			return (
				<p
					className={`my-heading number-${level}`}
					style={{ fontSize: `${level}px`, marginBottom: "8px" }}
				>
					{props.children}
				</p>
			)
		}

		return style === "blockquote" ? (
			<blockquote className="my-block-quote">{props.children}</blockquote>
		) : (
			<p className="my-paragraph">{props.children}</p>
		)
	}

	return (
		<BlockContent
			blocks={props.blocks}
			serializers={{ types: { block: BlockRenderer } }}
			projectId="2RO6V94YXJ"
			dataset="production"
		/>
	)
}

export default BlockArray
